import React, { useEffect, useState } from 'react'
import './css/winners.css'
import ReactModal from 'react-modal';
import { getRanking } from '../services/user-api';
import Confetti from 'react-confetti'

ReactModal.setAppElement('#root');

const Winners = ({ isOpen, closeModal }) => {

    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState();

    useEffect(() => {
        getRanking().then((res) => {        
            setUsers(res.data.data);
            setUserId(res.data.userId);
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    return (
        <>
            <ReactModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={false}
                shouldFocusAfterRender={false}
                shouldReturnFocusAfterClose={false}
                contentLabel="Winners Dialog"
                overlayClassName="winner-dialog-overlay"
                className="winner_dialog">
                <div className='winners_page'>
                    <section className='header_container'>
                        <div className='title_box'>
                            <img src='/images/crown.png' alt='Crown' />
                            <div className='title'>Top 10 Winners [Your Player ID - {userId}]</div>
                        </div>
                        <button className='close_btn' onClick={closeModal}>
                            <i className="fas fa-xmark"></i>
                        </button>
                    </section>
                    <section>
                        <table className="table">
                            <thead className="thead">
                                <tr>
                                    <th scope="col">Player ID</th>
                                    <th scope="col">Rank</th>
                                    <th scope="col">Time Spend</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.length > 0 &&
                                    users.map((user, index) => {
                                        return (
                                            <tr key={user.id}>
                                                <td>
                                                    <div className='player_name'>
                                                        <img src='/images/profile.png' alt='profile' />
                                                        <div>{user.id}</div>
                                                    </div>
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{formatTime(user.sou)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </section>
                </div>
                <Confetti
                    width={document.getElementsByClassName('winners_page')[0]?.clientWidth}
                    height={document.getElementsByClassName('winners_page')[0]?.clientHeight} />
            </ReactModal>
        </>
    )
}

export default Winners