import axios from 'axios';
import {baseUrl} from '../utils/constant';

const instance = axios.create({
    baseUrl
});

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/login'
            return
        }
        return Promise.reject(error);
    });

export default instance;