import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import Main from '../components/bwa/main';
import Sub from "../components/bwa/sub";
import Otp from "../components/bwa/otp";
import SubLba from "../components/lba/sub";
import OtpLba from "../components/lba/otp";
import SubSl from "../components/sl/sub";
import OtpSl from "../components/sl/otp";
import {service} from "../utils/constant";
import Success from '../components/success';
import Login from '../components/login/login';


const OpenRoute = () => {
    if(service=="bwa") //############ Botswana ###########
    {        
        return (                
            <Routes>
                <Route path='/' element={<Sub/>} /> 
                <Route path='/main' element={<Main/>} /> 
                <Route path='/sub' element={<Sub/>}  />
                <Route path='/otp' element={<Otp/>} />             
                <Route path='*' element={<Navigate to="/sub" />} /> 
            </Routes>
        )
    }
    else if(service=="lba") //########## Liberia ############
    { 
        return (
        
            <Routes>
                <Route path='/' element={<SubLba/>} /> 
                <Route path='/sub' element={<SubLba/>}/>   
                <Route path='/otp' element={<OtpLba/>} />  
                <Route path='/success' element={<Success/>} />     
                <Route path='*' element={<Navigate to="/sub" />} /> 
            </Routes>
        )
    }
    else if(service=="sl") //########## SIERRALEONE ############
    { 
        return (
        
            <Routes>
                <Route path='/' element={<SubSl/>} /> 
                <Route path='/sub' element={<SubSl/>}/>   
                <Route path='/otp' element={<OtpSl/>} />  
                <Route path='/success' element={<Success/>} /> 
                <Route path='/login' element={<Login/>} />    
                <Route path='*' element={<Navigate to="/sub" />} /> 
            </Routes>
        )
    }

};

export default OpenRoute;