import React, { useState, useEffect } from 'react'
import '../css/style.css';
import '../css/login1.css';
import { NavLink } from 'react-router-dom';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import { useNavigate } from 'react-router-dom';
import {subsl} from '../../services/user-api';
import {serviceId,Loader} from "../../utils/constant";

const Sub = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');    
    const [phone, setPhone] = useState(localStorage.getItem("sluvg"));
    const [plan, setPlan] = useState(3);
    const [flow, setFlow] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [message, setMessage] = useState({message: "",error: false});

    const find = (arr, obj) => {
        return arr.filter(o => Object.keys(obj).every(i => obj[i] === o[i]));
      };
      
 
    const handleRadioChange = async(input) =>{
        setPlan(input);
      }  
      
      const checkNumber = async(flag) => {
        //console.log("checkNumber="+phone+"::flag="+flag);
        setMessage({message: "",error: false});
        if(phone!=null && phone!=undefined)
        {
            if(phone.length>=8)
            {           
                var nm=phone;
                if(nm.length<10)nm="232"+nm;
                setLoading(true);
                await subsl({action:0,serviceId:serviceId,ani:nm}).then((res) => {            
                    console.log(res)
                    localStorage.setItem("token", res.data.data.accessToken);
                    window.location.href ="/home";
                }).catch((err) => {
                    console.log(err)
                    setFlow(true);
                }).finally(() => setLoading(false))
            }
            else{setMessage({message: "Please enter a valid number",error: true})}
        }
        //else {navigate("/otp");}
        else if(flag!=null && flag!='1'){setMessage({message: "Please enter a number",error: true})}
      };
    const handleButtonClick = async() => {
        console.log(phone);
        setMessage({message: "",error: false});
        if(phone.length>=8)
        {
            localStorage.setItem("sluvg",phone);  
            if(phone.substring(0,1)=="0")  setPhone(phone.substring(1));       
            if(phone.length<10)setPhone("232"+phone)
            setConfirm(true);
        }
        else if(phone.length<=0){setMessage({message: "Please enter a number",error: true})}
        else{setMessage({message: "Pleas enter a valid number",error: true})}
      };

        
    const confirmSub = async() => {
        console.log(phone);
        setMessage({message: "",error: false});
        if(phone.length>=8)
        {
            if(phone.length<10)setPhone("232"+phone)
            setLoading(true);
            await subsl({action:1,serviceId:serviceId,planId:plan,ani:phone,mode:'WAP'}).then((res) => {            
                console.log(res)
                localStorage.setItem("token", res.data.data.accessToken);
                window.location.href ="/success";
            }).catch((err) => {
                console.log(err)
                setMessage({message: err.message,error: true})
            }).finally(() => setLoading(false))
        }
        else if(phone.length<=0){setMessage({message: "Enter a number",error: true})}
        else{setMessage({message: "Enter a valid number",error: true})}
      };
      const cancel = async() => {
        setMessage({message: "",error: false});
        setConfirm(false);
      }
      

    useEffect(() => {
            checkNumber('1');
    }, []);

    return (       
        <div className='home'>
            <div className="maine-div">
                <div className="container-fluid box-wrapper login"> 
                    <div className='pt-5'>
                        <div className="login-thumbnail">
                            <img src="/images/uv_games_logo.png" alt="UVGames" />
                        </div>
                    </div>
                    <div className="login-header pb-4">
                        <div className="box-wrapper mx-auto text-center position-relative"> 
                            <span className="modal-level-title game-title">Welcome to UVGames</span>
                        </div>
                    </div>
                    <div className="login-info">
                        <div className="login-thumbnail2">
                            <img src="/images/login_img.png" alt="UVGames" />
                        </div>
                        {confirm?
                            <div className="text-center phone-info">
                                <h3 className="header-title mb-3 mt-3 text-center">Confirm your subscription</h3> 
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}  
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={confirmSub}>Confirm</button>}
                                <div className="resend_otp">
                                    <a className="send_otp_link" onClick={cancel}> &lt;&lt;&nbsp;Back</a>
                                 </div>
                            </div>
                        :
                        <> 
                          <h3 className="login-title mb-3 mt-3 text-center">Find new games based on new technology which are full with fun, entertainment and knowledge.</h3>
                         {flow?
                                    <>
                                        <h3 className="header-title mb-3 mt-3 text-center">Select your subscription plan</h3>  
                                        <div className="text-center radio">
                                            <label>
                                                <input type="radio" id="rd1" value="3" checked={plan === 3} onChange={() => handleRadioChange(3)} />
                                                Daily pack @2 leones
                                            </label>
                                            <label>
                                                <input type="radio" id="rd2" value="4" checked={plan === 4} onChange={() => handleRadioChange(4)} />
                                                Weekly pack @10 leones
                                            </label>
                                        </div>
                                        <div className="text-center phone-info">
                                            <input                                    
                                                    className="form-control form-mobile-no" 
                                                    type="number" 
                                                    inputMode="numeric" 
                                                    value={phone}
                                                    onChange={(event) => {setPhone(event.target.value);}}
                                                    placeholder='Enter Mobile Number'
                                                    disabled
                                                />   
                                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}        
                                                {loading ? <Loader />:<><br/><button className="btn btn-primary btn-pm-custom" type="button" onClick={handleButtonClick}>Subscribe</button></>}
                                        </div>
                                    </>
                                :
                                <>
                                      <h3 className="header-title mb-3 mt-3 text-center">Enter your number</h3>  
                                      
                                        <div className="text-center phone-info">
                                            <input                                    
                                                    className="form-control form-mobile-no" 
                                                    type="number" 
                                                    inputMode="numeric" 
                                                    onChange={(event) => {setPhone(event.target.value);}}
                                                    placeholder='Enter Mobile Number'
                                                />   
                                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}        
                                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={checkNumber}>Submit</button>}
                                        </div>
                                </>
                            }
                        </>
                         }
                    </div>                    
                </div>
            </div>
        </div>
    )
}

export default Sub