import React, { useState, useEffect } from 'react'
import '../css/style.css';
import '../css/login1.css';
import { NavLink } from 'react-router-dom';
import ReactCustomFlagSelect from 'react-custom-flag-select';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import {subsl} from '../../services/user-api';
import {serviceId,Loader} from "../../utils/constant";

const Otp = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState({message: "",error: false});
    const [flow, setFlow] = useState(false);
    const [otpId, setOtpId] = useState('');

    const find = (arr, obj) => {
        return arr.filter(o => Object.keys(obj).every(i => obj[i] === o[i]));
      };

      const cancel = async() => {
        setMessage({message: "",error: false});
        setFlow(false);
      }
      
    const confirmOtp = async() => {
        localStorage.setItem("lbaExist",false);
        setMessage({message: "",error: false});
       // if(validatePhoneNumber(phone)){            
            if(otp!=null && otp.length===4)
            {
                var nm=phone;
                if(nm.substring(0,1)=="0")nm=nm.substring(1);
                if(nm.length<10)nm="232"+nm
                setLoading(true);
                await subsl({action:4,serviceId:serviceId,ani: nm,otpId:otpId,otp:otp}).then((res) => { 
                        console.log(res)
                        if(res.data.responseCode===202) // already subscribed )
                        {
                            localStorage.setItem("lbaExist",true);
                            localStorage.setItem("token", res.data.data.accessToken);
                            window.location.href ="/success";                 
                        }  
                        else 
                        { 
                            localStorage.setItem("lbauvcann",nm);
                            window.location.href ="/sub";                       
                        } 
                }).catch((err) => {
                    setMessage({message: err.message, error: true});
                }).finally(() => setLoading(false))
            }
            else{ setMessage({ message: "OTP is not valid",error: true });}
        //}else{ setMessage({ message: "Phone Number is not valid",error: true });}
      }
      

    const handleNumInputsChange = (numInputs) => {
        setOtp(numInputs);
        setButtonDisabled(numInputs.length !== 4 || isNaN(numInputs));
      };
      
    const sendOtp = async() => {
        //console.log('phone='+phone);
        setMessage({message: "",error: false});
        if(phone.length>=8)
        {
            var nm=phone;
            if(nm.substring(0,1)=="0")nm=nm.substring(1);    
            if(nm.length<10)nm="232"+nm;           

            setLoading(true);
            await subsl({action:3,serviceId:serviceId,ani: nm}).then((res) => {
                setFlow(true);  
            }).catch((err) => {
                console.log(err);
                setMessage({message: err.message, error: true });
            }).finally(() => setLoading(false))
        }
        else if(phone.length<=0){setMessage({message: "Phone enter a number",error: true})}
        else{setMessage({message: "Phone enter a valid number",error: true})}
       // navigate('/profile');
      };
      useEffect(() => {    
        //setFlow(true); 
      
    }, []);
    return (      
        <div className='home'>
            <div className="maine-div">
                <div className="container-fluid box-wrapper login"> 
                    <div className='pt-5'>
                        <div className="login-thumbnail">
                            <img src="/images/uv_games_logo.png" alt="UVGames" />
                        </div>
                    </div>
                    <div className="login-header pb-4">
                        <div className="box-wrapper mx-auto text-center position-relative"> 
                            <span className="modal-level-title game-title">Welcome to UVGames!</span>
                        </div>
                    </div>
                    { flow?  
                            <div className="login-info">
                                <div className="login-thumbnail2">
                                    <img src="/images/login_img.png" alt="UVGames" />
                                </div>
                                <h3 className="login-title mb-3 mt-3 text-center">Enter OTP to confirm your number</h3>
                                                           
                                <div className="phone-info">

                                    <div className="otp_section">               
                                        <OtpInput
                                            value={otp}
                                            numInputs={4}
                                            renderInput={(props, index) => (
                                                <input
                                                    {...props}
                                                    type="tel"  // Use type="tel" to display the numeric keypad on mobile devices
                                                    inputMode="numeric"  // Specify the numeric input mode
                                                />
                                            )}
                                            inputStyle="form-control form-otp"
                                            onChange={handleNumInputsChange}
                                            />
                                    </div>
                                    {
                                        message.message && <div className="mb-3">
                                        <p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p>
                                        </div>
                                    }    
                                    {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={confirmOtp} disabled={ButtonDisabled}>Confirm</button>}
                                    <div className="resend_otp">
                                            <a className="send_otp_link" onClick={cancel}> &lt;&lt;&nbsp;Back</a>
                                        </div>
                                    
                                </div>
                            </div>
                            :
                            <div className="login-info">
                                <div className="login-thumbnail2">
                                    <img src="/images/login_img.png" alt="UVGames" />
                                </div>
                                <h3 className="login-title mb-3 mt-3 text-center">Enter your Number</h3>
                              
                                <div className="phone-info">
                                    <div className="phone-number-info">                            
                                        <input                                    
                                            className="form-control form-mobile-no" 
                                            type="number" 
                                            inputMode="numeric" 
                                            value={phone}
                                            onChange={(event) => {setPhone(event.target.value);}}
                                            placeholder='Enter Mobile Number'
                                        />                                            
                                    </div>                                  
                                    {
                                        message.message && <div className="mb-3">
                                        <p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p>
                                        </div>
                                    }
                                
                                    {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={sendOtp} disabled={ButtonDisabled}>Submit</button>}
                                   
                                </div>
                            </div>  
                    }
                </div>
            </div>
        </div>
    )
}

export default Otp