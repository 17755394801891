import axios from "axios";
import {xAuth,baseUrl} from "../utils/constant";

const headers = { 'Content-Type': 'application/json','x-auth': xAuth }
export const loginApi = async (userData) => {
    try {
        const response = await axios.post(`${baseUrl}/user/adminLogin`, userData);
        return response.data.data.token;
    } catch (e) {
        throw e.response.data;
    }
};

export const sendOtp = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/user/sendOtp`, data);
        return response.data.message;
    } catch (e) {
        throw e.response.data;
    }
};

export const login = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/user/login`, data,{headers:headers});
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};