import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import './css/style.css';
import './css/login1.css';
import { unsub } from '../services/user-api';
import {serviceId,Loader} from "../utils/constant";

const Success = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [lbaExist, setLbaExist] = useState(localStorage.getItem("lbaExist"));
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState({message: "",error: false});

      const validatePhoneNumber = (phoneNumber) => {
        // Define a regular expression for a typical 10-digit US phone number
        const phoneRegex = /^\d{10}$/;
      
        // Test the provided phone number against the regular expression
        return phoneRegex.test(phoneNumber);
      }
  

    const gotToHome = async() => {
        navigate("/home");
      };

 
    return (
            <div className='home'>
                <div className="maine-div">
                    <div className="container-fluid box-wrapper login"> 
                        <div className='pt-5'>
                            <div className="login-thumbnail">
                                <img src="/images/uv_games_logo.png" alt="UVGames" />
                            </div>
                        </div>
                        <div className="login-header pb-4">
                            <div className="box-wrapper mx-auto text-center position-relative"> 
                                <span className="modal-level-title game-title">Welcome to UVGames</span>
                            </div>
                        </div>
                        <div className="login-info">
                            <div className="login-thumbnail2">
                                <img src="/images/login_img.png" alt="UVGames" />
                            </div>
                            {lbaExist && lbaExist=="true"?
                              <h3 className="login-title-green mb-3 mt-3 text-center">You are already subscribed on service.</h3>
                            : <h3 className="login-title-green mb-3 mt-3 text-center">You have successfully subscribed the service.</h3>
                            }
                            <h3 className="login-title mb-3 mt-3 text-center">To access the service, click on below "Click Here" button.</h3>
                            {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={gotToHome}>Click Here</button>}                          
                        </div>
                    </div>
                </div>
            </div>
       
    )
}

export default Success